import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const FALLBACK_LOCALE = 'fr';
const DETECTION_OPTIONS = {
  order: ['localStorage', 'cookie'],
  caches: ['localStorage', 'cookie'],
};

const i18nWebProvider = (resources) =>
  i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      detection: DETECTION_OPTIONS,
      fallbackLng: FALLBACK_LOCALE,
      debug: false,
      react: {
        useSuspense: false,
      },
      interpolation: {
        escapeValue: false,
      },
    });
export default function createI18next(resources) {
  return i18nWebProvider(resources);
}
