import { apiSlice } from "./apiSlice";
const path = 'users'

const UserApi = apiSlice.injectEndpoints({
    endpoints: (builder) => {
        return {
            getCurrentUser: builder.query({
                query: () => `${path}/me/`,
                providesTags: (response) => {
                  let tags = [{ type: 'User', id: 'current'}];
                  if (response?.id) {
                    tags.push({ type: 'User', id: response.id });
                  }
                  return tags;
                },
            }),
            getUser: builder.query({
                query: (userId) => `${path}/${userId}/`,
                providesTags: (_,__,userId) => [{ type: 'User', id: userId }],
            }),
            getUsers: builder.query({
                query: (filters) => {
                const params = new URLSearchParams(filters).toString();
                return `${path}/?${params}`;
                },
                providesTags: [{ type: 'User', id: 'LIST' }],
            }),
            editUser: builder.mutation({
                query: ({userId, ...user}) => ({
                url: `${path}/${userId}/`,
                method: 'PATCH',
                body: user,
                }),
                invalidatesTags: (response ) => [
                { type: 'User', id: 'LIST' },
                { type: 'User', id: response?.id },
                ],
            }),
            addUser: builder.mutation({
                query: (user) => ({
                url: `${path}/`,
                method: 'POST',
                body: user,
                }),
                invalidatesTags: [
                    { type: 'User', id: 'LIST' },
                ],
            }),
            deleteUser: builder.mutation({
                query: (userId) => ({
                  url: `${path}/${userId}/`,
                  method: 'DELETE',
                }),
                invalidatesTags: (_,__,userId) => [
                { type: 'User', id: userId },
                { type: 'User', id: 'LIST' }
                ],
            }),
            postQontoCode: builder.mutation({
              query: ({userId, code}) => ({
                url: `${path}/${userId}/qonto_code/`,
                method: 'POST',
                body: { code },
              }),
                invalidatesTags: (response) => [
                  { type: 'User', id: response.id },
                  { type: 'User', id: 'LIST' },
                ]
            }),
        };
    },
    });

export const {
    useGetCurrentUserQuery,
    useLazyGetCurrentUserQuery,
    useGetUserQuery,
    useGetUsersQuery,
    useEditUserMutation,
    useAddUserMutation,
    useDeleteUserMutation,
    usePostQontoCodeMutation,
} = UserApi;