import { createApi } from '@reduxjs/toolkit/query/react';
import {
  baseQueryWithReauth,
  createBaseQuery,
} from '../../utils/apiUtils';

const baseQuery = createBaseQuery(
  `${process.env.REACT_APP_BACKEND_URL}drooky`,
);

export const apiSlice = createApi({
  reducerPath: 'apiDrooky',
  baseQuery: baseQueryWithReauth(baseQuery),
  tagTypes: [
    'Offer',
    'Offers',
    'Contract',
    'Contracts',
    'ContractItem',
    'ContractItems',
    'Claim',
    'Claims',
    'Quote',
    'Quotes',
    'Suivi',
    'Suivis',
    'User',
    'Users',
    'Emails',
    'Affiliate',
    'AffliliatePayment',
    'UserOfferAccess',
  ],
  endpoints: () => ({}),
});
