import React, { Suspense, useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useGetCurrentUserQuery } from './app/today/userApiSlice';
import LazyLoader from './utils/LazyLoader';

const TemplateLayout = React.lazy(() =>
  import('./components/TemplateLayout'),
);
const LoginPageLayout = React.lazy(() =>
  import('./components/TemplateLayoutLogin'),
);
const DashboardDrooky = React.lazy(() =>
  import('./components/drooky/dashboard'),
);
const SuiviList = React.lazy(() =>
  import('./components/drooky/suiviList'),
);
const Roles = React.lazy(() => import('./components/roles'));
const Rights = React.lazy(() => import('./components/rights'));
const QuoteList = React.lazy(() =>
  import('./components/drooky/quoteList'),
);
const ContractList = React.lazy(() =>
  import('./components/drooky/contractList'),
);
const ContractDetailsDrooky = React.lazy(() =>
  import('./components/drooky/contractDetails'),
);
const LoginPage = React.lazy(() => import('./components/login'));
const Affiliates = React.lazy(() =>
  import('./components/drooky/affiliate/AffiliateList'),
);
const AffiliateDetails = React.lazy(() =>
  import('./components/drooky/affiliate/AffiliateDetails'),
);
const DashboardEtiq = React.lazy(() =>
  import('./components/etiq/dashboard'),
);
const Contracts = React.lazy(() =>
  import('./components/etiq/contracts'),
);
const ClaimsEtiq = React.lazy(() =>
  import('./components/common/claims'),
);
const ClaimsDrooky = React.lazy(() =>
  import('./components/drooky/claimList'),
);
const Users = React.lazy(() =>
  import('./components/etiq/users/usersList'),
);
const ManageUser = React.lazy(() =>
  import('./components/etiq/users/user'),
);

const Bills = React.lazy(() => import('./components/etiq/bills'));
const Fraud = React.lazy(() => import('./components/etiq/fraud'));

const Admin = React.lazy(() => import('./components/etiq/admin'));
const CreateCollege = React.lazy(() =>
  import('./components/etiq/admin/college'),
);
const CreateMutualisation = React.lazy(() =>
  import('./components/etiq/admin/mutualisation'),
);
const CreateInsurer = React.lazy(() =>
  import('./components/etiq/admin/insurer'),
);
const CreateManager = React.lazy(() =>
  import('./components/etiq/admin/manager'),
);
const CreateFranchise = React.lazy(() =>
  import('./components/etiq/admin/companygroup'),
);
const CreateKnownCompany = React.lazy(() =>
  import('./components/etiq/admin/knowncompany'),
);
const ListTeleFail = React.lazy(() =>
  import('./components/etiq/admin/listTeleFail'),
);
const ClaimDetailsDrooky = React.lazy(() =>
  import('./components/drooky/claimDetails'),
);
const ClaimDetailsEtiq = React.lazy(() =>
  import('./components/etiq/claim'),
);

export default function Router() {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  const drookyRoutes = useMemo(() => {
    const services_routes = {
      suivi: [{ path: 'suivis', element: <SuiviList /> }],
      quote: [{ path: 'quotes', element: <QuoteList /> }],
      contract: [
        {
          path: 'contracts',
          children: [
            { index: true, element: <ContractList /> },
            {
              path: ':contractId',
              element: <ContractDetailsDrooky />,
            },
          ],
        },
        {
          path: 'dashboard',
          element: <DashboardDrooky />,
        },
      ],
      claim: [
        {
          path: 'claims',
          children: [
            { index: true, element: <ClaimsDrooky /> },
            { path: ':claimId', element: <ClaimDetailsDrooky /> },
          ],
        },
      ],
      affiliate: [
        {
          path: 'affiliates',
          name: 'Affiliates',
          children: [
            { index: true, element: <Affiliates /> },
            { path: ':affiliateId', element: <AffiliateDetails /> },
          ],
        },
      ],
    };

    let routes = [];
    Object.keys(services_routes).forEach((service) => {
      if (currentUser?.allowed_services[service]) {
        routes.push(...services_routes[service]);
      }
    });
    if (routes.length === 0) {
      return [];
    } else {
      const indexRoute = routes.find((r) => r.path === 'dashboard')
        ? 'dashboard'
        : routes[0].path;
      return [
        {
          path: '/drooky',
          children: [
            {
              element: <Navigate to={indexRoute} />,
              index: true,
            },
            ...routes,
          ],
        },
      ];
    }
  }, [currentUser]);

  const etiqRoutes = useMemo(() => {
    if (currentUser?.allowed_services?.etiq) {
      return [
        {
          path: '/etiq',
          children: [
            { path: '', element: <DashboardEtiq />, index: true },
            { path: 'contracts', element: <Contracts /> },
            { path: 'claims', element: <ClaimsEtiq /> },
            {
              path: 'claims/:claimId',
              element: <ClaimDetailsEtiq />,
            },
            { path: 'users', element: <Users /> },
            { path: 'users/:userId', element: <ManageUser /> },
            { path: 'bills', element: <Bills /> },
            { path: 'fraud', element: <Fraud /> },
            { path: 'admin', element: <Admin /> },
            {
              path: 'admin/mutualisation',
              element: <CreateMutualisation />,
            },
            { path: 'admin/college', element: <CreateCollege /> },
            { path: 'admin/manager', element: <CreateManager /> },
            { path: 'admin/insurer', element: <CreateInsurer /> },
            {
              path: 'admin/knowncompany',
              element: <CreateKnownCompany />,
            },
            {
              path: 'admin/companygroup',
              element: <CreateFranchise />,
            },
            {
              path: 'admin/teletransmissionFail',
              element: <ListTeleFail />,
            },
          ],
        },
      ];
    } else {
      return [];
    }
  }, [currentUser]);

  const routes = useRoutes(
    !currentUser
      ? [
          {
            path: '/login',
            element: <LoginPageLayout />,
            children: [
              {
                index: true,
                element: <LoginPage />,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to="/login" />,
          },
        ]
      : [
          {
            element: <TemplateLayout />,
            children: [
              ...drookyRoutes,
              ...etiqRoutes,
              {
                path: '/roles',
                children: [
                  { path: '', element: <Roles />, index: true },
                ],
              },
              {
                path: '/rights',
                children: [
                  { path: '', element: <Rights />, index: true },
                ],
              },
              {
                index: true,
                element: (
                  <Navigate
                    to={
                      drookyRoutes.length !== 0 ? '/drooky' : '/etiq'
                    }
                  />
                ),
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to="/" replace />,
          },
        ],
  );

  if (isLoading) {
    return <LazyLoader />;
  }

  return <Suspense fallback={<LazyLoader />}>{routes}</Suspense>;
}
