import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { apiSlice } from '../app/today/apiSlice';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const get_accessToken = () => localStorage.getItem('access_token') || null;
const get_refreshToken = () => localStorage.getItem('refresh_token') || null;

export const remove_token = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  apiSlice.util.invalidateTags([{type : 'User', id: 'current'}]);
}

export const set_token = (token) => {
  localStorage.setItem('access_token', token.access);
  localStorage.setItem('refresh_token', token.refresh);
  apiSlice.util.invalidateTags([{type : 'User', id: 'current'}]);
}


const axiosInstance = axios.create({
  baseURL,
  timeout: 0,
  headers: {
    Authorization: get_accessToken() ? `JWT ${get_accessToken()}` : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
  paramsSerializer: (params) =>
    new URLSearchParams(params).toString(),
});

Date.prototype.toJSON = function () {
  const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
  this.setHours(hoursDiff);
  return this.toISOString();
};

export const refreshToken = async () => {
  const refreshToken = get_refreshToken();
  if (!refreshToken) {
    return Promise.reject('No token stored');
  }
  const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
  // exp date in token is expressed in seconds, while now() returns milliseconds:
  const now = Math.ceil(Date.now() / 1000);
  if (tokenParts.exp < now) {
    remove_token()
    return Promise.reject('Token expired');
  }
  try {
    const response = await axiosInstance.post('/token/refresh/', {
      refresh: refreshToken,
    });
    set_token(response.data);
    return response;
  } catch (error) {
    remove_token()
    console.error('Error refreshing token:', error);
    throw error;
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data) {
        if (typeof response.data === 'string') {
          try {
            // Parse the string to see if it's valid JSON
            const parsed = JSON.parse(response.data);
            console.log(parsed);
            // return !(typeof parsed === 'object' && parsed !== null && !Array.isArray(parsed));
          } catch (e) {
            Swal.fire({
              text: response.data.replace(/['"]+/g, ''),
              icon: 'success',
              toast: true,
              position: 'top',
              timer: 2000,
              showClass: {
                backdrop: 'swal2-noanimation', // disable backdrop animation
                popup: '', // disable popup animation
                icon: '', // disable icon animation
              },
              background: 'green',
              color: 'white',
              iconColor: 'white',
              showConfirmButton: false,
            });
          }
        }
      }
      return Promise.resolve(response);
    } else return Promise.reject(response);
  },
  (error) => {
    const { response, config: originalRequest } = error;
    // Prevent infinite loops
    if (
      response.status === 401 &&
      originalRequest.url === `${baseURL}token/refresh/`
    ) {
      window.location.href = '/login';
      return Promise.reject(error);
    }
    if (
      !originalRequest._retry &&
      response.data.code === 'token_not_valid' &&
      response.status === 401
      // && response.statusText === "Unauthorized"
    ) {
      refreshToken().then(
        () => {
          // Retry the original request with the new token
          originalRequest.headers['Authorization'] = `JWT ${get_accessToken()}`;
          originalRequest._retry = true;
          return axiosInstance(originalRequest);
        }
      ).finally(()=>{
        // Update the Authorization header for future requests
        axiosInstance.defaults.headers['Authorization'] = `JWT ${response.data.access}`;
      })
    }

    // specific error handling done elsewhere
    console.log(error.response.data);
    if (error.response.data instanceof Blob) {
      error.response.data.text().then((text) =>
        Swal.fire({
          text: text.replace(/['"]+/g, ''),
          icon: 'error',
          toast: true,
          position: 'top',
          animation: false,
          showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '', // disable popup animation
            icon: '', // disable icon animation
          },
          background: '#d32f2f',
          color: 'white',
          iconColor: 'white',
          showConfirmButton: false,
        }),
      );
    } else
      Swal.fire({
        text: error.response.data.replace(/['"]+/g, ''),
        icon: 'error',
        toast: true,
        position: 'top',
        animation: false,
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '', // disable popup animation
          icon: '', // disable icon animation
        },
        background: '#d32f2f',
        color: 'white',
        iconColor: 'white',
        showConfirmButton: false,
      });

    const apiErrorInfo = {
      'API Request': {
        URL: `${originalRequest?.baseURL}${originalRequest?.url}`,
        Method: originalRequest?.method?.toUpperCase(),
        Data: originalRequest?.data?.slice(0, 500),
      },
      'API Response': {
        Status: error.response?.status,
        'Status text': error.response?.statusText?.slice(0, 500),
        Data: JSON.stringify(error.response?.data)?.slice(0, 500),
      },
    };
    const errorEvent = {
      message: `${error?.name} - ${error.message} on ${originalRequest.url}`,
      level: 'error',
      extra: apiErrorInfo,
    };
    try {
      datadogRum.addError('API Error', {
        error: apiErrorInfo,
        message: error?.message,
        stack: error?.stack,
      });
    } catch (e) {
      console.log(
        `Error while adding custom error to datadog : ${error.message}`,
      );
    }
    Sentry.captureEvent(errorEvent);

    return Promise.reject(error);
  },
);

export const isAuthenticated = () => {
  return !!get_accessToken();
};

export const logoutUser = () => {
  remove_token()
  window.location.href = '/';
};

export default axiosInstance;
